import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { StyledButtonLink } from './IconButton.styled';

export const IconButtonLink = ({ hrefAs, href, icon, className }) => (
  <Link passHref
href={href}
as={hrefAs}
legacyBehavior>
    <StyledButtonLink className={className}>{icon}</StyledButtonLink>
  </Link>
);

IconButtonLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]).isRequired,
  hrefAs: PropTypes.string,
  icon: PropTypes.node.isRequired,
};

IconButtonLink.defaultProps = {
  className: undefined,
  hrefAs: undefined,
};
